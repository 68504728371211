body {
	background-color: #282c34;
}

.App-section {
	background-color: #282c34;
	min-height: 60vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	margin-top: 20px;
}  

.noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
		-webkit-tap-highlight-color: transparent;
		
}

button {
	width: 150px;
	height: 50px;
	cursor: pointer;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #7f5a83;
	background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);
	background-size: 200% 100%;
	background-position: left;
	background-repeat: no-repeat;
	transition: 500ms;
}

button:before {
	content: 'CHOOSE';
	color: rgba(255,255,255,0.5);
	font-size: 18px;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 140px;
	height: 40px;
	background-color: #1b2845;
	background-image: linear-gradient(315deg, #1b2845 0%, #274060 74%);
	transition: 500ms;
}

button:hover {
	background-position: right;
}

button:hover:before {
	color: rgba(255,255,255,0.8);
}

button:focus {
	outline: none;
}
  
.select:before {
    content: 'TRANSLATE';
    color: rgba(255,255,255,0.5);
    font-size: 18px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 40px;
    background-color: #1b2845;
    background-image: linear-gradient(315deg, #1b2845 0%, #274060 74%);
    transition: 500ms;
}

.topic {
	margin: 10px 0 10px 0;
	font-size: 30px; 
	letter-spacing: -1px; 
	color: #c2c1c4;
	text-transform: uppercase; 
	text-shadow: 3px 2px 4px #164994;
	margin: 10px; 
	text-align: center; 
	line-height: 50px;
	border-bottom: 1px solid #cac8d2;
}

.translateButton {
	justify-content: center;
	align-items: center;
	padding: 10px;
}

.container {
	display: flex;
	justify-content: space-around;
	background-color: #282c34;
	font-size: calc(10px + 2vmin);
  	color: white;
	margin-top: -40px;
}

.colLeft {
	/* border: 1px solid pink; */
	padding: 30px;
	margin: 0px 40px 30px 40px;
	/* width: 400px; */
}

.colRight {
	/* border: 1px solid rgb(99, 29, 41); */
	padding: 30px;
	margin: 0px 40px 30px 40px;
	/* width: 400px; */
}

.spaceButton {
	padding: 10px;
	margin-bottom: 10px;
}

.mainParagraph {
	color: #cac8d2; 
	text-transform: uppercase;
	text-shadow: 3px 2px 4px #164994;
	margin: 20px; 
	text-align: center;
	font-size: 50px;
}

.word {
	font-size: 22px;
}
